@import "base";
@import "font";

.en-font {
  font-family: "English font";
}

$color: #27a3cf;
$fontColor: #3f3a39;
/*
*分页样式居中
*/
.g-pagination {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  & > ul {
    display: flex;

    & > li {
      width: 40px;
      height: 40px;

      &:nth-of-type(1) {
        & > a {
          display: inline-block;
          font-size: 20px;
          text-align: center;
          line-height: 36px;
          padding: 0;
        }

        & > span {
          display: inline-block;
          font-size: 20px;
          text-align: center;
          line-height: 36px;
          padding: 0;
          position: relative;
          float: left;
          margin-left: -1px;
          color: #337ab7;
          text-decoration: none;
          background-color: #fff;
          border: 1px solid #ddd;
        }
      }

      &:nth-last-of-type(1) {
        & > a {
          display: inline-block;
          font-size: 20px;
          text-align: center;
          line-height: 35px;
          padding: 0;
        }

        & > span {
          display: inline-block;
          font-size: 20px;
          text-align: center;
          line-height: 35px;
          padding: 0;
        }
      }

      & > a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -1px;
        color: #337ab7;
        background-color: #fff;
        border: 1px solid #ddd;
        font-size: 14px;
      }

      & > span {
        width: 40px;
        height: 40px;
        display: inline-block;
        line-height: 40px;
        text-align: center;
        padding: 0;
        font-size: 14px;
      }

      &.active {
        & > span {
          z-index: 3;
          color: #fff;
          cursor: default;
          background-color: #337ab7;
          border-color: #337ab7;
          padding: 1px;
        }
      }
    }
  }

}


/*
url(/static/airice/images-mobile/home/logo.png)
*/
#app {
  background-color: #fafafa;

  .app-header {
    position: relative;
    height: 44px;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #fff;

    .app-logo {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -10px;
      margin-left: -50px;
      width: 100px;
      height: 20px;
      text-align: center;
      background-image: url(/static/airice/images-mobile/home/logo.png);
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
    }

    .app-menus {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -5px;
      width: 18px;
      height: 10px;
      -webkit-background-size: cover;
      background-size: cover;
      background-image: url(/static/airice/images-mobile/home/menu.png);
    }
  }

  .app-content {
   background-color: #f0f0f0;
    .app-margin {
      margin-top: 20px;
    }
     .app-padding {
      padding-top: 20px;
    }

    .app-box {
      height: 221px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      & > h3 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #fff;
      }

      & > p {
        font-size: 14px;
        color: #fff;
      }

      .app-product {
        height: 155px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        &>a{
          display: block;
          text-align: center;
        &>h3{
          font-size: 18px;
          margin-bottom: 5px;
          color: #fff;
        }
        &>p{
          font-size: 16px;
          color: #fff;
        }
        }
         &>h3{
          font-size: 18px;
          margin-bottom: 5px;
          color: #fff;
        }
        &>p{
          font-size: 16px;
          color: #fff;
        }
      }

      .app-box-tab {
        width: 100%;
        display: flex;
        flex: 1;

        & > li {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          -webkit-background-size: cover;
          background-size: cover;
          background-repeat: no-repeat;

          & > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > p {
              font-size: 14px;
              color: #fff;
            }

            & > span {
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }
    }

    .app-example {
      height: 228px;
      background-color: #fff;

      .app-example-list {
        position: relative;
        &>div{
          &>a{
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }
        }
        height: 235px;
        h3 {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .app-view-detail {
          text-align: center;
          margin-bottom: 5px;

          & > a {
            color: #27a3cf;
          }
        }

        .app-case-img {
          height: 170px;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .app-new-img {
          height: 170px;
          background-size: cover;
          background-repeat: no-repeat;
        }

        a {
          display: block;
          h3 {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            width: 210px;
            margin: 0 auto 10px auto;
            min-height: 48px;
            height: 48px;
          }

          .app-case-img {
            height: 170px;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .app-new-img {
            height: 170px;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }

      .case-container {
        .case-pagination {
          bottom: 18px;
        }

        h3 {
          margin-bottom: 20px;
        }
      }
    }

    &.app-product {
      width: 100%;
      .machine-duck {
        margin-top: 30px;
        height: 237px;
        width: 100%;
        background-image: url(/static/airice/images-mobile/product/duck.png);
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        & > p {
          padding-top: 18px;
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .app-product-box{
        background-color: #fff;
        padding-top: 8px;
      }
      .app-product-list {
        height: 164px;
        margin-top: 8px;
        background-position: 0 1px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        &:nth-of-type(1){
          margin-top: 0;
        }
        & > h3 {
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        & > p {
          color: #fff;
          font-size: 14px;
          line-height: 22px;
          width: 80%;
        }
      }

      .app-specialist {
        background-color: #fff;
        margin-top: 30px;
        & > h3 {
          padding-top: 12px;
          padding-bottom: 17px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }

        & > p {
          padding: 0 15px 22px 15px;
          font-size: 16px;
          line-height: 28px;
          color: #404040;
        }

        .app-specialist-bg {
          height: 202px;
          width: 100%;
          background-image: url(/static/airice/images-mobile/product/system_farm.png);
        }
      }

      .app-image-text {
        background-color: #fff;
        position: relative;
        margin-top: 30px;
        & > h3 {
          padding-top: 12px;
          padding-bottom: 17px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }

        & > p {
          padding: 0 15px 22px 15px;
          font-size: 16px;
          line-height: 28px;
          color: #404040;
        }

        & > a {
          width: 160px;
          height: 30px;
          line-height: 30px;
          color: #fff;
          position: absolute;
          text-align: center;
          bottom: 80px;
          left: 50%;
          margin-left: -80px;
          background-color: rgba(30, 158, 225, 0.8);
          border-radius: 15px;

          &:hover {
            color: #fff !important;
          }
        }

        .app-image-bg {
          height: 202px;
          width: 100%;
        }
      }
    }

    &.app-tech {
      .app-integration-content {
        padding-top: 30px;
        .app-tech-integration {
          background-color: #fff;

          & > p {
            padding: 0 15px;
            font-size: 16px;
            line-height: 24px;
          }

          .app-integration-big {
            position: relative;
            margin-top: 20px;
            height: 220px;
            width: 100%;
            background-image: url(/static/airice/images-mobile/tech/tech-integration.png);
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;

            .app-integration-small {
              position: absolute;
              left: 50%;
              top: 50%;
              margin-top: -40px;
              margin-left: -125px;
              width: 250px;
              height: 80px;
              background-image: url(/static/airice/images-mobile/tech/circle-big.png);
              -webkit-background-size: 100% 100%;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              color: #fff;

              .tech-merge-text {
                position: relative;
                height: 100%;
                width: 100%;

                & > span {
                  position: absolute;
                  font-size: 14px;
                  white-space: nowrap;

                  &:nth-of-type(1) {
                    left: 80px;
                    bottom: -20px;
                  }

                  &:nth-of-type(2) {
                    left: -5px;
                    bottom: -8px;
                  }

                  &:nth-of-type(3) {
                    left: -45px;
                    bottom: 35px;
                  }

                  &:nth-of-type(4) {
                    left: 30px;
                    top: -13px;
                  }

                  &:nth-of-type(5) {
                    left: 105px;
                    top: -20px;
                  }

                  &:nth-of-type(6) {
                    left: 206px;
                    top: -10px;
                  }

                  &:nth-of-type(7) {
                    right: -60px;
                    bottom: 20px;
                  }

                  &:nth-of-type(8) {
                    right: 30px;
                    bottom: -15px;
                  }
                }
              }

              .tech-merge-center {
                position: absolute;
                display: flex;
                width: 100px;
                margin-left: -50px;
                left: 50%;
                bottom: 40%;
                justify-content: center;
                align-items: center;
                color: #fff;

                & > span {
                  display: inline-block;
                  position: relative;
                  width: 20px;
                  height: 20px;
                  background: url(/static/airice/images-mobile/tech/circle-samll.png) center center no-repeat;
                }

                & > p {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .app-tech-box {
          margin-top: 30px;

          .tech-box-title {
            height: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-repeat: no-repeat;
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;

            & > span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              background-image: url(/static/airice/images-mobile/tech/circle-samll.png);
              background-repeat: no-repeat;
              background-position: center;
            }

            & > p {
              color: #fff;
            }
          }
          .tech-list-box{
            background-color: #fff;
            padding-top: 10px;
          }
          .tech-list {
            height: 170px;
            margin-top: 10px;
            color: #fff;
            background-repeat: no-repeat;
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
            &:nth-of-type(1){
              margin-top: 0;
            }
            & > h3 {
              padding-top: 32px;
              padding-bottom: 5px;
              font-size: 16px;
              font-weight: bold;
              text-align: center;
            }

            & > p {
              font-size: 14px;
              padding: 0 15px 22px 15px;
            }
          }
        }
      }
    }

    &.app-case {
      .app-case-box {
        .app-case-list {
          .app-case-title {
            background-color: #fff;
            margin-bottom: 30px;

            & > h3 {
              text-align: center;
              padding-top: 20px;
              margin-bottom: 10px;
              font-size: 18px;
            }

            & > p {
              margin: 0 15px 20px 15px;
              font-size: 16px;
              color: #404040;
              line-height: 28px;
            }

            .case-list-bg {
              height: 170px;
              -webkit-background-size: 100% 100%;
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.app-news {
      .app-news-box {

        .app-new-list {
          margin: 30px 15px 0 15px;
          background-color: #fff;
          position: relative;
          &>a{
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
            .app-news-title {
              display: flex;
              justify-content: center;
              padding-top: 22px;

              & > h3 {
                & > a {
                  display: block;
                  width: 210px;
                  text-align: center;
                  font-size: 1rem;
                  line-height: 24px;
                }
              }
            }

            .new-list-bg {
              margin-top: 15px;
              height: 164px;
              -webkit-background-size: cover;
              background-size: cover;
            }

            .app-new-text {
              margin-top: 15px;
              padding: 0 15px;
              font-size: 0.75rem;
              text-align: justify;
              color: #666666;
            }

            .app-new-footer {
              padding: 20px 15px 15px 15px;
              display: flex;
              justify-content: space-between;

              & > a {
                color: #27a3cf;
                font-size: 16px;
                &:nth-of-type(1) {
                  color: #666666;
                  cursor: text;
                  font-size: 0.75rem;
                }
              }
            }

        }
      }
    }

    &.news-detail {
      position: relative;
      .app-box {
        & > h3 {
          width: 210px;
          text-align: center;
          z-index: 99;
          font-size: 18px;
        }

        & > div {
          position: absolute;
          height: 221px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .app-news-detail {
      .news-detail {
        margin: 0 15px;

        & > p {
          font-size: 16px;
          line-height: 28px;
          color: #404040;
          text-align: justify;
        
          &:nth-of-type(1) {
            margin-top: 30px;
          }

          &:nth-last-of-type(1) {
            margin-bottom: 30px;
          }

          & > img {
            width: 100% !important;
            height: auto !important;
          }
        }

        & > img {
          width: 100% !important;
          height: auto !important;
        }
      }

      .news-footer {
        margin: 0 15px 10px 15px;
        display: flex;
        justify-content: space-between;

        .news-footer-left {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;

          & > span {
            display: inline-block;
            font-size: 12px;
            color: #666666;

            &:nth-of-type(1) {
              margin-right: 5px;
            }
          }
        }

        .news-footer-right {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          & > span {
            display: inline-block;
            cursor: pointer;
            font-size: 12px;
            white-space: nowrap;

            &:nth-of-type(2) {
              width: 30px;
              height: 30px;
              margin: 0 10px 0 5px;
              background: #fff url(/static/airice/images-mobile/news/sina.png) center center no-repeat;
              -webkit-background-size: cover;
              background-size: cover;
            }

            &:nth-of-type(3) {
              width: 24px;
              height: 24px;
              background: #fff url(/static/airice/images-mobile/news/wx.png) 0 0 no-repeat;
            }
          }

          .share-code {
            display: none;
            position: absolute;
            right: -120px;
            top: -30px;
          }
        }
      }
    }

    &.app-about-us {
      .app-about-tab {
        width: 100%;
        position: absolute;
        top: 44px;
        z-index: 99;

        .app-about-title {
          display: flex;
          justify-content: space-between;
          height: 32px;
          background-color: #000;

          & > div {
            height: 32px;
            line-height: 32px;
            margin-left: 15px;

            & > p {
              display: none;
              color: $color;

              &.show {
                display: block;
              }
            }
          }

          & > span {
            display: inline-block;
            width: 15px;
            height: 10px;
            margin-top: 11px;
            margin-right: 15px;
            background-image: url(/static/airice/images-mobile/about/open.png);
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            &.hide-nav {
              transform: rotate(180deg);
            }
          }

        }

        .about-tab-nav {
          display: none;
          position: absolute;
          width: 100%;

          & > ul {
            padding-left: 56px;
            padding-right: 56px;
            padding-bottom: 100px;
            background-color: #000;

            & > li {
              height: 45px;
              line-height: 45px;
              border-bottom: 1px solid #3f3a39;

              & > a {
                height: 45px;
                color: #fff;

                &.active {
                  color: $color;
                }
              }
            }
          }
        }
      }

      .app-news-bg {
        padding-top: 76px;
      }

      .app-about-box {
        background-color: #fff;
        padding: 20px 0;
        .app-about-nav {
          display: none;

          &.active {
            display: block;
          }

          &.about-company {
            margin: 10px 15px 0 15px;

            .about-company-text {
              & > p {
                text-indent: 2em;
                text-align: justify;
                font-size: 16px;
                line-height: 28px;
                color: #404040;
              }
            }

            .about-company-img {
              margin-top: 15px;
              height: 170px;
              background-image: url(/static/airice/images-mobile/about/about-compant-bg.png);
            }
          }

          &.about-culture {
            .about-culture-box {
              margin: 0 15px;

              & > h3 {
                padding-top: 24px;
                font-size: 18px;
                text-align: center;
              }

              & > p {
                margin: 15px 0;
                text-indent: 2em;
                text-align: justify;
                font-size: 16px;
                line-height: 28px;
                color: #404040;
              }

              .about-culture-bg {
                height: 170px;
                -webkit-background-size: cover;
                background-size: cover;
              }
            }
          }

          &.about-development {
            margin: 38px 38px 0 38px;

            .about-journey {
              position: relative;

              &:before {
                content: '';
                position: absolute;
                left: 4px;
                width: 4px;
                background-color: $color;
                height: 100%;
              }

              .about-journey-list {
                position: relative;

                &:nth-of-type(1) {
                  padding-top: 20px;

                  & > span {
                    top: 24px;
                  }
                }

                & > span {
                  position: absolute;
                  left: 0;
                  top: 4px;
                  display: inline-block;
                  width: 12px;
                  height: 12px;
                  background-color: $color;
                  border-radius: 50%;
                }

                .about-journey-describe {
                  padding-left: 30px;

                  & > p {
                    font-size: 16px;
                  }

                  .about-journey-date {
                    color: $color;
                    margin-bottom: 10px;
                  }

                  .about-journey-text {
                    margin-bottom: 30px;
                  }
                }
              }
            }
          }

          &.about-team {
            margin: 0 15px;

            & > a {
              display: block;

              & > div {
                margin-top: 30px;
              }
            }

            .about-team-item {
              position: relative;
              height: 250px;
              background-image: url(/static/airice/images-mobile/about/about-item-bg.png);

              .about-item-box {
                display: flex;
                width: 310px;
                height: 126px;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -155px;
                margin-top: -63px;
                background-image: url(/static/airice/images-mobile/about/item-bg.png);
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center 1px;

                & > div {
                  &:nth-of-type(1) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 15px;
                    color: #fff;

                    & > h3 {
                      font-size: 16px;
                      margin-top: 15px;
                    }

                    & > h4 {
                      font-size: 14px;
                      margin: 5px 0 3px 0;
                    }

                    & > p {
                      font-size: 12px;
                      margin-bottom: 3px;
                    }
                  }

                  &:nth-of-type(2) {
                    position: absolute;
                    height: 150px;
                    width: 100px;
                    right: 0;
                    bottom: 0;
                  }
                }
              }
            }

            .about-team-list {
              padding-top: 40px;
              padding-bottom: 40px;
              background-color: #24559a;

              .about-list-box {
                position: relative;
                width: 214px;
                height: 90px;
                background-color: #0c2335;
                margin: 10px auto;

                & > div {
                  color: #fff;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  margin-left: 15px;

                  &.about-right {
                    margin-left: 30px;
                    align-items: center;
                  }

                  & > div {
                    display: flex;
                    margin-bottom: 3px;

                    & > h3 {
                      font-size: 16px;
                    }

                    & > p {
                      font-size: 13px;
                      position: relative;
                      left: 5px;
                      top: 3px;
                    }
                  }

                  & > p {
                    font-size: 12px;
                    margin-bottom: 2px;
                  }
                }

                .about-list-img {
                  position: absolute;
                  display: inline-block;
                  width: 90px;
                  height: 90px;
                  left: -45px;
                  top: 0;
                  border-radius: 50%;
                  -webkit-background-size: 100% 100%;
                  background-size: 100% 100%;

                  &.right-position {
                    left: auto;
                    right: -45px;
                  }
                }
              }
            }

            .about-team-unite {
              background-color: #217fb5;
              padding: 20px 0;

              .about-unite-box {
                margin: 0 15px;

                .unite-box {
                  background-color: red;
                  margin-bottom: 12px;
                  height: 54px;

                  & > div {
                    background-color: #0d2338;
                    display: flex;

                    .unite-box-li {
                      display: flex;
                      align-items: center;
                      color: #fff;
                      margin-left: 5px;

                      & > h3 {
                        font-size: 16px;
                        margin: 0 5px;
                      }

                      & > p {
                        font-size: 12px;
                        margin: 0 5px;
                        font-weight: 300;
                      }

                      & > div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 5px;

                        & > p {
                          font-size: 12px;
                        }
                      }
                    }

                    & > span {
                      display: block;
                      width: 54px;
                      height: 54px;
                      background-color: orange;
                    }
                  }

                  .right-direction {
                    display: flex;
                    justify-content: flex-end;
                  }
                }
              }
            }
          }

          &.about-join {
            margin: 0 15px;

            & > .join {
              display: block;

              & > ul {
                & > li {
                  border-bottom: 1px solid #cccccc;
                  padding-bottom: 15px;

                  & > h3 {
                    padding-top: 35px;
                    font-family: "fangz font";
                    font-size: 20px;
                    color: $color;
                    text-align: center;
                  }

                  & > h4 {
                    padding-top: 20px;
                    font-size: 16px;
                    font-weight: bold;
                  }

                  & > span {
                    display: inline-block;
                    font-size: 16px;
                    color: $color;
                    margin: 10px 0 5px 0;

                    &:nth-of-type(2) {
                      margin-top: 5px;
                    }
                  }

                  & > div {
                    font-size: 14px;
                    color: $fontColor;
                  }

                  & > div {
                    &.text-em {
                      text-indent: -1rem;
                      margin-left: 1rem;
                    }

                    & > ol > li {
                      font-size: 14px;
                      color: $fontColor;
                      line-height: 22px;
                      text-indent: -1.5rem;
                      margin-left: 1.5rem;
                      text-align: justify;
                    }
                  }
                }
              }
            }

            & > .join1 {
              display: block;
              padding-bottom: 14px;
              padding-top: 10px;

              & > h4 {
                padding: 20px 0 5px 0;
                font-size: 18px;
                font-weight: bold;
              }

              & > ul {
                & > li {
                  & > span, & > p {
                    font-size: 14px;
                    line-height: 28px;
                    color: $fontColor;

                    &:nth-of-type(1) {
                      color: $color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  .app-footer {
    background-color: #f3f3f3;
    padding: 30px 30px 50px 30px;

    .app-footer-link {
      .app-link-model {
        & > p {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 22px;
          padding-top: 32px;

          & > a {
            color: #000;
          }
        }

        & > ul {
          display: flex;
          flex-wrap: wrap;

          & > li {
            width: 41%;
            display: flex;
            margin-bottom: 15px;

            & > a {
              color: #000;
              font-size: 14px;
            }
          }
        }
      }
    }

    .app-qrcode {
      height: 100px;
      width: 100px;
      background: #fff url(/static/airice/images-mobile/home/code.jpg) 0 0 no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      margin-top: 32px;
      box-sizing: border-box;
    }
  }
}

#app-menus-mask {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  z-index: 999;

  .app-mask-header {
    position: relative;
    height: 44px;

    .app-mask-logo {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -10px;
      margin-left: -50px;
      width: 100px;
      height: 20px;
      text-align: center;
      background-image: url(/static/airice/images-mobile/home/white_logo.png);
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .app-mask-close {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -7px;
      width: 14px;
      height: 14px;
      -webkit-background-size: cover;
      background-size: cover;
      background-image: url(/static/airice/images-mobile/home/close.png);
      transition: all 200ms;
    }
  }

  .app-mask-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #3f3a39;

    & > ul {
      width: 300px;

      & > li {
        height: 45px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #3f3a39;

        & > a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
}


.swiper-pagination-bullet {
  opacity: 1;
  background-color: #fff;
}

.swiper-pagination-bullet-active {
  background-color: #27a3cf;
}

.mask {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, .2);
  transition: background .2s ease;
  pointer-events: none;
}
